import axiosClient from '@/helper/call-center';

export const MembershipApi = {
  getAll() {
    return axiosClient.get(`/membership`);
  },
  getMembershipByLocationAndId: (customerId: string, branchId: string) =>
    axiosClient.get(`/membership/by-id-customer/${customerId}/${branchId}`),
  getDetailByLocation(location: string) {
    return axiosClient.get(`/membership/by-location/${location}`);
  },
  getListMembershipByLocation(location: string) {
    return axiosClient.get(`/membership-category/by-location/${location}`);
  },
  createCard(params: { is_recurring_enabled?: boolean; categoryId: string }) {
    return axiosClient.post('/membership/register-card', params);
  },
  createCardCategory(params: IMembershipCategory) {
    return axiosClient.post('/membership/register-card', params);
  },
  updateCardCategory(id: string) {
    return axiosClient.put(`/membership-category/update-image/${id}`);
  },
  updateAutoRenewalCard(membershipCardId: string, status: boolean) {
    return axiosClient.patch(`/membership/change-enable-auto-renew/${membershipCardId}`, {
      is_recurring_enabled: status,
    });
  },
  createCardByEmployee(params: { is_recurring_enabled?: boolean; categoryId: string; customerId: string }) {
    const { customerId, ...rest } = params;
    return axiosClient.post(`/membership/register-card-by-employee/${customerId}`, rest);
  },
  deleteCard(params: { membershipId: string }) {
    return axiosClient.delete(`/membership/cancel/${params.membershipId}`);
  },
  changeServiceFreeTarget(params: { id: string; serviceId: string }) {
    const { id, serviceId } = params;
    return axiosClient.patch(`/membership-category/by-id/${id}/change-service-free-target/${serviceId}`);
  },
  changeAddonFreeTarget(params: { id: string; addonId: string }) {
    const { id, addonId } = params;
    return axiosClient.patch(`/membership-category/by-id/${id}/change-addon-free-target/${addonId}`);
  },
  regularByLocation(params: { location: string; search?: string }) {
    const { location, ...rest } = params;
    return axiosClient.get(`/membership/regular/${location}`, { params: rest });
  },
};

interface IMembershipCategory {
  name: string;
  description: string;
  day_counts: number;
  price: number;
  freeServicesIds: [{ count: number; target: string }];
  freeAddonsIds: [{ count: number; target: string }];
  storeBranchId: string;
  discountAddon: string;
  discountServices: string;
  discountProducts: string;
  pointMultiplier: number;
}
