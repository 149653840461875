import { Checkbox } from 'antd';
import Image from 'next/image';

interface IMembershipCardProps {
  name: string;
  description: string;
  price: number;
  date?: string;
  image: any;
  showCheckbox?: boolean;
  setCardSelected?: any;
  cardSelected?: any;
  _id?: string;
  category?: any;
}

const MemberShipCard = (props: IMembershipCardProps) => {
  const {
    name,
    description,
    price,
    date,
    image,
    showCheckbox = true,
    setCardSelected,
    cardSelected,
    _id,
    category,
  } = props;

  return (
    <div className='flex items-center gap-4 rounded-2xl bg-white p-3'>
      <div className='relative size-[100px] rounded-lg'>
        <Image src={image || category.image} alt='img' fill className='h-full w-full rounded-lg object-cover' />
      </div>

      <div className='flex flex-1 flex-col rounded-lg'>
        <span className='text-[16px] font-semibold text-zelene-dark-blue'>{name}</span>
        <span className='text-[14px] font-medium text-zelene-grey'>{description}</span>

        <div className='flex items-center gap-2 pt-3'>
          <span className='font-hneu text-sm font-medium text-zelene-red'>${price}.00 USD</span>
          <span className='size-1.5 rounded-full bg-zelene-blue'></span>
          {<span className='flex items-center font-hneu text-[12px] text-zelene-grey'>{date} days</span>}
        </div>
      </div>

      {showCheckbox && (
        <Checkbox
          checked={cardSelected === _id}
          onChange={e => {
            if (cardSelected === _id && e.target.checked === true) {
              return setCardSelected(null);
            } else {
              return setCardSelected(_id);
            }
          }}
        />
      )}
    </div>
  );
};

export default MemberShipCard;
