import moment from 'moment';
import { FC } from 'react';

interface IMembershipDateLeft {
  name: string;
  total_date: number;
  extension_at: string;
}

export const MembershipDateLeft: FC<IMembershipDateLeft> = props => {
  const { total_date, extension_at, name } = props;

  const extensionDate = moment(extension_at);
  const currentDate = moment();

  const differenceInDays = currentDate.diff(extensionDate, 'days');
  return (
    <div className='flex flex-col gap-2 rounded-lg bg-white px-5 py-4'>
      <div className='flex gap-8 px-4 *:flex-1'>
        <div className='font-hneu text-[12px] text-zelene-slight-blue'>Plan</div>
        <div className='font-hneu text-[12px] text-zelene-slight-blue'>Expiration Date</div>
      </div>

      <div className='flex gap-8 bg-[#FBF5EA] px-4 py-3 *:flex-1'>
        <div className='font-hneu text-[12px] font-medium text-zelene-blue'>{name}</div>

        <div className='font-hneu text-[12px] font-medium text-zelene-blue'>
          {total_date - differenceInDays} Days Left
        </div>
      </div>
    </div>
  );
};
