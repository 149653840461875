import { MembershipApi } from '@/api-client/membership';
import { useAuth } from '@/hooks/auth.hook';
import { IMembershipCategories } from '@/interface/membership';
import { Tabs } from 'antd';
import Image from 'next/image';
import { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Membership from '../../../../../../public/svgs/Membership.svg';
import { MembershipDetailItem } from './item';

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 0;
  }

  .ant-tabs-nav-list {
    width: 100%;
    display: flex !important;
  }

  .ant-tabs-nav {
    margin-bottom: 0;
    transition: all 0.3s ease-in-out;
    &::before {
      border: none !important; /* Remove bottom border */
    }
  }

  .ant-tabs-content-holder {
    padding-top: 24px !important;
  }

  .ant-tabs-nav-list {
    // background-color: #f0f2f5;
    border-radius: 6px;
    padding: 4px;
    gap: 4px;
  }

  .ant-tabs-tab-btn {
    width: 100%;
  }

  .ant-tabs-tab {
    margin: 0 !important;
    padding: 6px 16px;
    border-radius: 6px;
    color: #637381;
    font-weight: 500;
    transition: all 0.3s;
    flex: 1;
    justify-content: center;
    text-align: center;

    &:hover {
      color: #1d3f75;
    }

    &.ant-tabs-tab-active {
      background-color: #1d3f75;

      .ant-tabs-tab-btn {
        color: white !important;
      }
    }
  }

  .ant-tabs-ink-bar {
    display: none;
  }
`;

interface ICustomerMembershipDetail {
  storeBrandId: string;
}

export const CustomerMembershipDetail: FC<ICustomerMembershipDetail> = props => {
  const { storeBrandId } = props;

  const { profile } = useAuth();

  const [MembershipCategories, setMembershipCategories] = useState<IMembershipCategories[]>([]);
  // const [categoryChoose, setCategoryChoose] = useState<string | null>(null);

  const fetchMembershipCategories = useCallback(async () => {
    const { data } = await MembershipApi.getListMembershipByLocation(storeBrandId);

    setMembershipCategories(data);
  }, [storeBrandId]);

  useEffect(() => {
    fetchMembershipCategories();
  }, [fetchMembershipCategories]);

  return (
    <StyledTabs
      className='w-full'
      centered
      defaultActiveKey='1'
      // onChange={key => {
      //   setCategoryChoose(key);
      // }}
      items={MembershipCategories.map(category => {
        const target = profile.memberships?.find(value => {
          return category._id === value.category._id;
        });

        return {
          key: category._id,
          label: target ? (
            <div className='flex items-center justify-center gap-2'>
              <Image src={Membership} alt='icon' width={16} height={16} className='size-4 object-contain' />
              {category.name}
            </div>
          ) : (
            category.name
          ),
          children: <MembershipDetailItem item={category} membershipCard={target} />,
        };
      })}
    />
  );
};
