interface IMembershipPolicy {
  key: string;
  benefits: string[];
  pricing: string[];
}
export const membershipPolicy: IMembershipPolicy[] = [
  // Frisco
  {
    //Essential
    key: '2420971f-4ab3-45ae-b18a-1ab8aba3c0f7',
    benefits: ['x1 point for every purchase', 'Monthly Guest Pass', 'Private Events', 'Birthday Gifts'],
    pricing: ['10% Services', '10% Products'],
  },
  {
    //Premium
    key: '6488a5b4-9604-429b-88b6-5cf4324ff2d6',
    benefits: ['x1.5 point for every purchase', 'Monthly Guest Pass', 'Private Events', 'Birthday Gifts'],
    pricing: ['15% Services', '15% Products'],
  },
  {
    //Elite
    key: '00084920-d1be-4aef-a63f-801d78072782',
    benefits: ['x2 point for every purchase', 'Monthly Guest Pass', 'Private Events', 'Birthday Gifts'],
    pricing: ['20% Services', '20% Products'],
  },
  //Family & Friend
  {
    key: '18bc5ca1-e90b-447d-92f9-3cde6a0de8ef',
    benefits: ['x2 point for every purchase', 'Up to 5 Monthly Guest Pass', 'Private Events', 'Birthday Gifts'],
    pricing: ['20% Services', '20% Products'],
  },

  // Mansfield
  {
    //Essential
    key: '42ae3b7d-f3b7-4fb9-a842-f33ed6e85ef6',
    benefits: ['x1 point for every purchase', 'Monthly Guest Pass', 'Private Events', 'Birthday Gifts'],
    pricing: ['10% Services', '10% Products'],
  },
  {
    //Premium
    key: 'e7d54f75-e530-4c14-98ce-8bd06006bc63',
    benefits: ['x1.5 point for every purchase', 'Monthly Guest Pass', 'Private Events', 'Birthday Gifts'],
    pricing: ['15% Services', '15% Products'],
  },
  {
    //Elite
    key: '01426718-ed2f-44a5-a8fb-4936c6aff7c8',
    benefits: ['x2 point for every purchase', 'Monthly Guest Pass', 'Private Events', 'Birthday Gifts'],
    pricing: ['20% Services', '20% Products'],
  },
  //Family & Friend
  {
    key: '549a2e9f-3d51-4d4c-9f82-2407e9db55d8',
    benefits: ['x2 point for every purchase', 'Up to 5 Monthly Guest Pass', 'Private Events', 'Birthday Gifts'],
    pricing: ['20% Services', '20% Products'],
  },

  // Flower Mound
  {
    //Essential
    key: 'a38b23d9-ddc5-467f-882f-0186233a0d26',
    benefits: ['x1 point for every purchase', 'Monthly Guest Pass', 'Private Events', 'Birthday Gifts'],
    pricing: ['10% Services', '10% Products'],
  },
  {
    //Premium
    key: 'a129779d-c65a-4229-81ca-2aaa1ac7cfe2',
    benefits: ['x1.5 point for every purchase', 'Monthly Guest Pass', 'Private Events', 'Birthday Gifts'],
    pricing: ['15% Services', '15% Products'],
  },
  {
    //Elite
    key: '6a0cb18b-57e4-4f61-b987-ed4fb2d89202',
    benefits: ['x2 point for every purchase', 'Monthly Guest Pass', 'Private Events', 'Birthday Gifts'],
    pricing: ['20% Services', '20% Products'],
  },
  //Family & Friend
  {
    key: 'a2e96f15-60fb-4e7d-9e9c-c788c7a89aa2',
    benefits: ['x2 point for every purchase', 'Up to 5 Monthly Guest Pass', 'Private Events', 'Birthday Gifts'],
    pricing: ['20% Services', '20% Products'],
  },
];
